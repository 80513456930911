import Topbar from "./components/Topbar/Topbar";
import Projects from "./components/Projects/Projects";
import Menu from "./components/Menu/Menu";
import './App.scss';
import { useState } from "react";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="app">
      <Topbar open={menuOpen} set={setMenuOpen}/>
      <Menu open={menuOpen} set={setMenuOpen}/>
      <div className="sections ">
        <Projects open={menuOpen} set={setMenuOpen}/>
      </div>
    </div>
  )
}

export default App;