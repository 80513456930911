import './Projects.scss';
import WebIcon from '@material-ui/icons/Web';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';

export default function Projects({open, set}) {
    return (
        <div className={"projects " + (open && "active")} id="projects">
            <div className="slider1">
                <div className="container">
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <WebIcon className="logo"/>
                                <h2>Algorithm Sorting Visualizer</h2>
                                <p>
                                Interactive website that displays different sorting algorithms.
                                </p>
                                <a
                                    className="link"
                                    href="https://yangmetro.com/sorting-algo-visualizer/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Website
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <img src="./SortingVisualizer.PNG" alt = "img"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider2">
                <div className="container">
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <AccessTimeIcon className="logo"/>
                                <h2>MyTodo</h2>
                                <p>
                                Simple terminal task-tracker written in C.
                                </p>
                                <a
                                    className="link"
                                    href="https://github.com/yangmetro/MyTodo"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <img src="./mytodo.png" alt = "img"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="slider3">
                <div className="container">
                    <div className="item">
                        <div className="left">
                            <div className="leftContainer">
                                <EventIcon className="logo"/>
                                <h2>NBA Games Calendar</h2>
                                <p>
                                Web application that displays NBA schedules on a calendar.
                                </p>
                                <a
                                    className="link"
                                    href="https://github.com/yangmetro/sportstracker"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Github
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <img src="./sportstracker.png" alt = "img"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
