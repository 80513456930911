import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import "./Menu.scss";

export default function Menu({open, set}) {
    return (
        <div className={"menu " + (open && "active")}>
            <ul>
                <a href="https://github.com/yangmetro"
                    target="_blank"   
                    rel="noopener noreferrer"
                >
                    <GitHubIcon className="icon"/>
                </a>
            </ul>
            <ul>
                <a href="https://www.linkedin.com/in/yangmetro/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <LinkedInIcon className="icon"/>
                </a>
            </ul>
        </div>
    )
}
