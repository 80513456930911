import './Topbar.scss';


export default function Topbar({open, set}) {
    return (
        <div className={"topbar " + (open && "active")}>
            <div className="wrapper">
                <div className="left">
                    <a href="/" className="logo">Brian Yang</a>
                </div>
                <div className="right">
                    <div className="triple" onClick={()=>set(!open)}>
                        <span className="line1">

                        </span>
                        <span className="line2">
                            
                        </span>
                        <span className="line3">
                            
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
